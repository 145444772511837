#home_page {
  min-height: 87dvh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1rem;
  .avatar {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    .avatar_container {
      width: 70%;
      border-radius: 50%;
      overflow: hidden;
      box-shadow: 5px 5px 8px 0px rgba(0, 0, 0, 0.5);
      animation: show 2s ease-in;
    }
  }
  .opening {
    font-size: 3rem;
    flex: 1;
    text-align: center;
    opacity: 0;
    animation: show 2s ease-in both;
  }
}

@keyframes show {
  from {opacity: 0;}
  to {opacity: 1;}
}

@media (max-width: 1020px) {
  #home_page {
    .opening {
      font-size: 2.5rem;
    }
  }
}
@media (max-width: 850px) {
  #home_page {
    .opening {
      font-size: 2rem;
    }
  }
}
@media (max-width: 690px) {
  #home_page {
    .opening {
      font-size: 1.5rem;
    }
  }
}
@media (max-width: 690px) {
  #home_page {
    display: block;
    .avatar {
      padding-top: 5rem;
    }
    .opening {
      padding-top: 4rem;
      font-size: 2rem;
    }
  }
}
@media (max-width: 380px) {
  #home_page {
    .opening {
      font-size: 1.5rem;
    }
  }
}
