#portfolio {
  min-height: 87dvh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h1 {
    height: 3rem;
    line-height: 3rem;
    margin-top: 1rem;
    text-align: center;
    margin-bottom: 1rem;
    font-weight: 300;
  }
  .portfolio_container {
    display: flex;
    justify-content: start;
    flex-wrap: wrap;
    margin: 0 5%;
  }
}

@media (max-width:460px) {
  #portfolio {
    h1 {
      font-size: 1.25rem;
    }
    .portfolio_container {
      flex-direction: column;
      width: 90%;
    }
  }
}