#about {
  min-height: 87dvh;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  .information {
    width: 35%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .avatar_container2 {
      height: 15rem;
      width: 15rem;
      border-radius: 50%;
      border: solid 3px #c2caca;
      overflow: hidden;
    }
    h1 {
      margin-top: 2rem;
      font-size: 2rem;
      font-weight: 400;
    }
    h2 {
      font-size: 2rem;
      font-weight: 300;
    }
    .social_media {
      display: flex;
      margin-top: 1rem;
      a {
        display: block;
        overflow: hidden;
        margin-right: 1rem;
        height: 2.5rem;
        width: 2.5rem;
        border-radius: 50%;
        &:hover {
          background-color: #d3d3d3;
        }
      }
    }
    .contact {
      div {
        display: flex;
        align-items: center;
        margin-top: 1rem;
        img {
          height: 2.5rem;
          width: 2.5rem;
          margin-right: 1rem;
        }
      }
    }
  }
  .description {
    width: 40%;
    display: flex;
    flex-direction: column;
    h1 {
      font-weight: 300;
    }
    p {
      margin-top: 1rem;
      font-weight: 300;
      font-size: 0.875rem;
    }
    .interest {
      margin-top: 2rem;
    }
  }
}

@media (max-width: 600px) {
  #about {
    flex-direction: column;
    gap: 1rem;
    padding: 5% 10%;
    .information {
      width: 70%;
      .avatar_container2 {
        height: 12rem;
        width: 12rem;
      }
      h1 {
        font-size: 1.5rem;
      }
      h2 {
        font-size: 1.5rem;
      }
      .contact{
        div{
          span{
            font-size: 0.875rem;
          }
        }
      }
      
    }
    .description {
      width: 80%;
    }
  }
}
