#portfolio_card {
  display: flex;
  flex-direction: column;
  width: 20%;
  aspect-ratio: 1/1;
  height: auto;
  color: black;
  margin: 1rem 2.5%;
  // border-radius: 10px;
  overflow: hidden;
  // 玻璃擬態
  background-color: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.15),
    inset -1px -1px 1px rgba(255, 255, 255, 0.3);
    transition: 1s;
  &:hover {
    transform: scale(1.1);
  }
  .web_img {
    width: 100%;
    height: 60%;
  }
  .web_text {
    height: 40%;
    padding: 1rem;
    .web_name {
      font-weight: 300;
      padding-bottom: 0.5rem;
      font-size: 1rem;
    }
    .desc {
      font-weight: 300;
      font-size: 0.75rem;
    }
  }
}

@media (max-width:1000px) {
  #portfolio_card{
    width: 29%;
    margin: 1rem 2%;
  }
}
@media (max-width:700px) {
  #portfolio_card{
    width: 45%;
    margin: 1rem 2.5%;
  }
}

@media (max-width:460px) {
  #portfolio_card {
    width: 100%;
    aspect-ratio: 3/1.25;
    height: auto;
    margin: 1rem 0;
    flex-direction: row;
    .web_img {
      width: 55%;
      height: 100%;
    }
    .web_text {
      width: 45%;
      padding: 0.75rem;
      .web_name {
        font-weight: 300;
        padding-bottom: 0.5rem;
        font-size: 0.875rem;
      }
      .desc {
        font-weight: 300;
        font-size: 0.75rem;
      }
    }
  }
}

