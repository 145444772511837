@import "~@csstools/normalize.css";
@import url("https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@200;300;400;500;600;800;900&display=swap");
@import "./nav";
@import "./homepage";
@import "./about";
@import "./portfolio";
@import "./portfolioCard";
@import "./experience";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
ul {
  list-style: none;
}
a {
  text-decoration: none;
}
img {
  display: block;
  width: 100%;
}
html,body {
  overflow-x: hidden;
  font-family: "Source Code Pro", monospace;
}
