@charset "UTF-8";
@import "~@csstools/normalize.css";
@import url("https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@200;300;400;500;600;800;900&display=swap");
header {
  height: 10dvh;
  background-color: #c2caca;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
header .to_home {
  margin-left: 2rem;
  font-size: 2.5rem;
  font-weight: 600;
  color: #fff;
  text-shadow: 0 0.005em 0 #ccc, 0 0.01em 0 #c9c9c9, 0 0.015em 0 #bbb, 0 0.02em 0 #b9b9b9, 0 0.025em 0 #aaa, 0 0.03em 0.005em rgba(0, 0, 0, 0.1), 0 0 0.025em rgba(0, 0, 0, 0.1), 0 0.005em 0.015em rgba(0, 0, 0, 0.3), 0 0.015em 0.025em rgba(0, 0, 0, 0.2), 0 0.025em 0.05em rgba(0, 0, 0, 0.25), 0 0.05em 0.05em rgba(0, 0, 0, 0.2), 0 0.1em 0.1em rgba(0, 0, 0, 0.15);
}
header .navigation {
  margin-right: 2rem;
}
header .navigation ul {
  display: flex;
}
header .navigation ul li {
  margin: 0 1rem;
}
header .navigation ul li a {
  display: block;
  font-size: 1.25rem;
  color: #515151;
  border-bottom: solid 2px transparent;
}
header .navigation ul li a:hover, header .navigation ul li a.active {
  color: white;
  border-bottom: solid 2px white;
}
header .hamburger {
  display: none;
}

.ham-nav {
  background-color: #fff;
  box-shadow: inset -1px -1px 1px rgba(255, 255, 255, 0.3), -2px 2px 5px rgba(0, 0, 0, 0.15);
  max-height: 0;
  overflow: hidden;
  transition: all 1s ease;
}
.ham-nav ul {
  display: flex;
  flex-direction: column;
}
.ham-nav ul li {
  margin: 1rem 0;
  text-align: center;
}
.ham-nav ul li a {
  display: inline-block;
  font-size: 1.25rem;
  color: black;
  border-bottom: solid 2px transparent;
}
.ham-nav ul li a:hover, .ham-nav ul li a.active {
  color: #c9c9c9;
  border-bottom: solid 2px #c9c9c9;
}

/* 漢堡按鈕 */
@media (max-width: 600px) {
  header .navigation {
    display: none;
  }
  header .hamburger {
    display: inline-block; /* 自動抓內容(.bar)的尺寸 */
    cursor: pointer;
    /* 取消button預設樣式 */
    background-color: transparent;
    border: none;
    padding: 0;
    margin-right: 20px;
  }
  header .hamburger.is-active .bar:nth-child(2) {
    opacity: 0;
  }
  header .hamburger.is-active .bar:nth-child(1) {
    transform: translateY(8px) rotate(45deg);
  }
  header .hamburger.is-active .bar:nth-child(3) {
    transform: translateY(-8px) rotate(-45deg);
  }
  header .hamburger .bar {
    display: block; /* 修改行內元素為區塊元素 */
    width: 25px;
    height: 3px;
    border-radius: 25%;
    margin: 5px auto;
    background-color: #fff;
    box-shadow: 0 0.005em 0 #ccc, 0 0.01em 0 #c9c9c9, 0 0.015em 0 #bbb, 0 0.02em 0 #b9b9b9, 0 0.025em 0 #aaa, 0 0.03em 0.005em rgba(0, 0, 0, 0.1), 0 0 0.025em rgba(0, 0, 0, 0.1), 0 0.005em 0.015em rgba(0, 0, 0, 0.3), 0 0.015em 0.025em rgba(0, 0, 0, 0.2), 0 0.025em 0.05em rgba(0, 0, 0, 0.25), 0 0.05em 0.05em rgba(0, 0, 0, 0.2), 0 0.1em 0.1em rgba(0, 0, 0, 0.15);
    transition: all 0.3s ease-in-out;
  }
  .ham-nav.show {
    max-height: 200px;
  }
}
#home_page {
  min-height: 87dvh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1rem;
}
#home_page .avatar {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
#home_page .avatar .avatar_container {
  width: 70%;
  border-radius: 50%;
  overflow: hidden;
  box-shadow: 5px 5px 8px 0px rgba(0, 0, 0, 0.5);
  animation: show 2s ease-in;
}
#home_page .opening {
  font-size: 3rem;
  flex: 1;
  text-align: center;
  opacity: 0;
  animation: show 2s ease-in both;
}

@keyframes show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@media (max-width: 1020px) {
  #home_page .opening {
    font-size: 2.5rem;
  }
}
@media (max-width: 850px) {
  #home_page .opening {
    font-size: 2rem;
  }
}
@media (max-width: 690px) {
  #home_page .opening {
    font-size: 1.5rem;
  }
}
@media (max-width: 690px) {
  #home_page {
    display: block;
  }
  #home_page .avatar {
    padding-top: 5rem;
  }
  #home_page .opening {
    padding-top: 4rem;
    font-size: 2rem;
  }
}
@media (max-width: 380px) {
  #home_page .opening {
    font-size: 1.5rem;
  }
}
#about {
  min-height: 87dvh;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
#about .information {
  width: 35%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
#about .information .avatar_container2 {
  height: 15rem;
  width: 15rem;
  border-radius: 50%;
  border: solid 3px #c2caca;
  overflow: hidden;
}
#about .information h1 {
  margin-top: 2rem;
  font-size: 2rem;
  font-weight: 400;
}
#about .information h2 {
  font-size: 2rem;
  font-weight: 300;
}
#about .information .social_media {
  display: flex;
  margin-top: 1rem;
}
#about .information .social_media a {
  display: block;
  overflow: hidden;
  margin-right: 1rem;
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 50%;
}
#about .information .social_media a:hover {
  background-color: #d3d3d3;
}
#about .information .contact div {
  display: flex;
  align-items: center;
  margin-top: 1rem;
}
#about .information .contact div img {
  height: 2.5rem;
  width: 2.5rem;
  margin-right: 1rem;
}
#about .description {
  width: 40%;
  display: flex;
  flex-direction: column;
}
#about .description h1 {
  font-weight: 300;
}
#about .description p {
  margin-top: 1rem;
  font-weight: 300;
  font-size: 0.875rem;
}
#about .description .interest {
  margin-top: 2rem;
}

@media (max-width: 600px) {
  #about {
    flex-direction: column;
    gap: 1rem;
    padding: 5% 10%;
  }
  #about .information {
    width: 70%;
  }
  #about .information .avatar_container2 {
    height: 12rem;
    width: 12rem;
  }
  #about .information h1 {
    font-size: 1.5rem;
  }
  #about .information h2 {
    font-size: 1.5rem;
  }
  #about .information .contact div span {
    font-size: 0.875rem;
  }
  #about .description {
    width: 80%;
  }
}
#portfolio {
  min-height: 87dvh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#portfolio h1 {
  height: 3rem;
  line-height: 3rem;
  margin-top: 1rem;
  text-align: center;
  margin-bottom: 1rem;
  font-weight: 300;
}
#portfolio .portfolio_container {
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
  margin: 0 5%;
}

@media (max-width: 460px) {
  #portfolio h1 {
    font-size: 1.25rem;
  }
  #portfolio .portfolio_container {
    flex-direction: column;
    width: 90%;
  }
}
#portfolio_card {
  display: flex;
  flex-direction: column;
  width: 20%;
  aspect-ratio: 1/1;
  height: auto;
  color: black;
  margin: 1rem 2.5%;
  overflow: hidden;
  background-color: rgba(255, 255, 255, 0.2);
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.15), inset -1px -1px 1px rgba(255, 255, 255, 0.3);
  transition: 1s;
}
#portfolio_card:hover {
  transform: scale(1.1);
}
#portfolio_card .web_img {
  width: 100%;
  height: 60%;
}
#portfolio_card .web_text {
  height: 40%;
  padding: 1rem;
}
#portfolio_card .web_text .web_name {
  font-weight: 300;
  padding-bottom: 0.5rem;
  font-size: 1rem;
}
#portfolio_card .web_text .desc {
  font-weight: 300;
  font-size: 0.75rem;
}

@media (max-width: 1000px) {
  #portfolio_card {
    width: 29%;
    margin: 1rem 2%;
  }
}
@media (max-width: 700px) {
  #portfolio_card {
    width: 45%;
    margin: 1rem 2.5%;
  }
}
@media (max-width: 460px) {
  #portfolio_card {
    width: 100%;
    aspect-ratio: 3/1.25;
    height: auto;
    margin: 1rem 0;
    flex-direction: row;
  }
  #portfolio_card .web_img {
    width: 55%;
    height: 100%;
  }
  #portfolio_card .web_text {
    width: 45%;
    padding: 0.75rem;
  }
  #portfolio_card .web_text .web_name {
    font-weight: 300;
    padding-bottom: 0.5rem;
    font-size: 0.875rem;
  }
  #portfolio_card .web_text .desc {
    font-weight: 300;
    font-size: 0.75rem;
  }
}
#experience {
  min-height: 87dvh;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
#experience .ex_left {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
#experience .ex_left .education h1 {
  font-weight: 300;
  margin-bottom: 1rem;
}
#experience .ex_left .education p {
  font-weight: 300;
}
#experience .ex_left .work_ex {
  margin-top: 3rem;
}
#experience .ex_left .work_ex h1 {
  font-weight: 300;
  margin-bottom: 1rem;
}
#experience .ex_left .work_ex p {
  font-weight: 300;
}
#experience .ex_right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#experience .ex_right .skill h1 {
  font-weight: 300;
  margin-bottom: 1rem;
}
#experience .ex_right .skill ul {
  font-weight: 300;
  list-style: circle;
}
#experience .ex_right .skill ul li {
  margin-bottom: 1rem;
}

@media (max-width: 600px) {
  #experience {
    flex-direction: column;
    align-items: start;
    padding: 0 15%;
  }
  #experience h1 {
    font-size: 1.25rem;
  }
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

img {
  display: block;
  width: 100%;
}

html, body {
  overflow-x: hidden;
  font-family: "Source Code Pro", monospace;
}/*# sourceMappingURL=style.css.map */